@import 'styles/app.scss';

.rotate {
    rotate: 180deg;
}

.map-font {
    font-size: 10px;
    font-family: $fontManrope;
    color: $colorAccent;
    font-weight: 700;
    word-break: break-all;
}