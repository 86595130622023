@import "variables";

//section {
//    line-height: 0;
//}
:where(.css-dev-only-do-not-override-12upa3x).ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
	border-bottom: 1px solid $colorDarkWhite !important;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
	border-bottom: 1px solid $colorDarkWhite !important;
}

.m-0-auto {
	margin: 0 auto;
}

.customCard {
	padding: 16px;
	margin-bottom: 20px;
	height: fit-content;
}

.bgImage {
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -3;
	max-height: 100%;
	height: 100%;
	width: 100%;
}

.spinner {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 9999;
}

.colorAccent {
	color: $colorAccent !important;
}

.colorWhite {
	color: $colorWhite !important;
}

.colorWhite60 {
	color: $colorWhite !important;
	opacity: .6 !important;
}

.colorWhite70 {
	color: $colorWhite !important;
	opacity: .7 !important;
}

.colorDarkWhite {
	color: $colorDarkWhite !important;
}

.colorLightGray {
	color: $colorLightGray !important;
}

.colorGray {
	color: $colorGray !important;
}

.colorGradient {
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-color: #2AA5A0;
	background-image: linear-gradient(135deg, #4072A3, #7EA2BC);
}

.bgGradient {
	background: $colorGradient !important;
}

.bgTransparent-white {
	background: linear-gradient(90deg, #4072A3, #fff) border-box !important;
}

.bgLightGrey {
	background: $colorLightGray !important;
}

.bgModal {
	background: $colorWhite !important;
}

ul, ol, li {
	list-style: none;
}

h1, h2, h3, h4, h5, h6 {
	//font-weight: inherit;
	font-size: inherit;
}

.pos {
	position: relative;
	z-index: 1;

	&-abs {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	&-abs-y {
		top: 50%;
		transform: translate(0, -50%);
	}

	&-abs-x {
		left: 50%;
		transform: translate(-50%, 0);
	}
}

.ta-center {
	text-align: center;
}

/* --z-index-- */
.z-1 {
	z-index: 1 !important;
}

.z-5 {
	z-index: 5 !important;
}

.z-7 {
	z-index: 7 !important;
}

/* --Width & Height Classes-- */
@media (max-width: $screenSm) {
	.h-100-p-mobile {
		height: 100% !important;
	}
}

.h-100-p {
	height: 100% !important;
}

.max-w-100-p {
	max-width: 100% !important;
}

.h-84 {
	.css-1nmdiq5-menu {
		height: 84px;
	}
}

.h-122 {
	.css-1nmdiq5-menu {
		height: 122px;
	}
}

.h-180 {
	height: 180px;
}

.max-h-400 {
	max-height: 400px !important;
}

.h-740 {
	height: 740px;
}

.h-800 {
	height: 800px;
}

.w-20 {
	width: 20px;
}

.w-40 {
	width: 40px;
}

.w-60 {
	width: 60px;
}

.w-200 {
	width: 200px;
}

.w-210 {
	width: 210px;
}

.w-300 {
	width: 300px;
}

.max-w-320 {
	max-width: 320px;
}

.max-w-520 {
	max-width: 520px;
}

.max-w-700 {
	max-width: 700px;
}

.w-50-p {
	width: 50%;
}

.w-80-p {
	width: 80%;
}

.w-100-p {
	width: 100%;
}

/* --Other-- */
img {
	max-width: 100% !important;
}

.t-decor-none {
	text-decoration: none;
}

.overflowNoHidden {
	overflow: inherit !important;
}

.d-none {
	display: none !important;
}

.d-flex {
	display: flex !important;
}

.d-block {
	display: block !important;
}

.ai-start {
	align-items: flex-start !important;
}

.jc-center {
	justify-content: center !important;
}

[class*="pos-abs"] {
	position: absolute;
}

.pos {
	position: relative;
	z-index: 1;

	&-abs {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	&-abs-y {
		top: 50%;
		transform: translate(0, -50%);
	}

	&-abs-x {
		left: 50%;
		transform: translate(-50%, 0);
	}
}

/* --z-index-- */
.z-5 {
	z-index: 5;
}

img {
	max-width: 100% !important;
}

// Position
.top-170 {
	top: 170px !important;
}

/* --Width & Height Classes-- */
.h-inherit {
	height: inherit;
}

.mh-100-vh {
	min-height: 100vh !important;
}

.mh-500 {
	min-height: 500px !important;
}

.h-100-p {
	height: 100% !important;
}

.h-40 {
	height: 40px;
}

.h-130 {
	height: 130px;
}

.h-137 {
	height: 137px;
}

.w-100-p {
	width: 100%;
}

.w-60 {
	width: 60px;
}

.w-100 {
	width: 100px;
}

.w-140 {
	width: 140px;
}

.w-150 {
	width: 150px;
}

.w-290 {
	width: 290px;
}

.w-300 {
	width: 300px;
}

.w-393 {
	width: 393px;
}

.w-408 {
	width: 408px !important;
}

.w-500 {
	width: 500px !important;
}

.w-520 {
	width: 520px;
}

.w-600 {
	width: 600px;
}

.w-620 {
	width: 620px;
}

.w-750 {
	width: 750px;
}

/* --Max-Width Classes-- */

.mw-505 {
	max-width: 505px;
}

/* --Padding Classes-- */
.p-0 {
	padding: 0;
}

.p-40 {
	padding: 40px;
}

.pt-2 {
	padding-top: 2px;
}

.pt-7 {
	padding-top: 7px;
}

.pt-8 {
	padding-top: 8px;
}

.pt-10 {
	padding-top: 10px;
}

.pt-12 {
	padding-top: 12px;
}

.pt-12 {
	padding-top: 12px;
}

.pt-20 {
	padding-top: 20px;
}

.pt-30 {
	padding-top: 30px;
}

.pt-40 {
	padding-top: 40px;
}

.pt-50 {
	padding-top: 50px;
}

.pt-60 {
	padding-top: 60px;
}

.pt-60-40 {
	padding-top: 60px;
}

@media (min-width: 1200px) {
	.pt-60-40 {
		padding-top: 40px;
	}
}

.pt-60-80 {
	padding-top: 60px;
}

@media (min-width: 1400px) {
	.pt-60-80 {
		padding-top: 80px;
	}
}

.pt-70 {
	padding-top: 70px;
}

.pt-70-20 {
	padding-top: 70px;
}

@media (max-width: 1200px) {
	.pt-70-20 {
		padding-top: 20px;
	}
}

.pt-100 {
	padding-top: 100px;
}

.pt-220 {
	padding-top: 220px;
}

.pt-250 {
	padding-top: 250px;
}

.pb-8 {
	padding-bottom: 8px;
}

.pb-10 {
	padding-bottom: 10px;
}

.pb-16 {
	padding-bottom: 16px;
}

.pb-20 {
	padding-bottom: 20px;
}

.pb-30 {
	padding-bottom: 30px;
}

.pb-40 {
	padding-bottom: 40px;
}

.pb-40-60 {
	padding-bottom: 40px;
}

@media (min-width: 1023px) {
	.pb-40-60 {
		padding-bottom: 60px;
	}
}

.pb-50 {
	padding-bottom: 50px;
}

.pb-60 {
	padding-bottom: 60px;
}

.pb-80 {
	padding-bottom: 80px;
}

.pb-40-100 {
	padding-bottom: 40px;
}

@media (min-width: 1200px) {
	.pb-40-100 {
		padding-bottom: 100px;
	}
}

.pb-160 {
	padding-bottom: 160px;
}

.pl-4 {
	padding-left: 4px;
}

.pl-10 {
	padding-left: 10px;
}

.pl-16 {
	padding-left: 16px;
}

.pl-20 {
	padding-left: 20px;
}

.pl-30 {
	padding-left: 30px;
}

.pl-40 {
	padding-left: 40px;
}

.pl-50 {
	padding-left: 50px;
}

.pl-90 {
	padding-left: 90px;
}

.pl-200 {
	padding-left: 200px;
}

.pl-220 {
	padding-left: 220px;
}

.pl-250 {
	padding-left: 250px;
}

.pl-280 {
	padding-left: 280px;
}

.pr-10 {
	padding-right: 10px;
}

.pr-20 {
	padding-right: 20px;
}

.pr-30 {
	padding-right: 30px;
}

.pr-40 {
	padding-right: 40px;
}

.pr-50 {
	padding-right: 50px;
}

/* --Margin Classes-- */
.m-0 {
	margin: 0;
}

.mt_160 {
	margin-top: -160px;
}

.mt_202 {
	margin-top: -202px;
}

.mt-5 {
	margin-top: 5px;
}

.mt-8 {
	margin-top: 8px;
}

.mt-10 {
	margin-top: 10px;
}

.mt-12 {
	margin-top: 12px;
}

.mt-15 {
	margin-top: 15px;
}

.mt-16 {
	margin-top: 16px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-24 {
	margin-top: 24px;
}

.mt-27 {
	margin-top: 27px;
}

.mt-28 {
	margin-top: 28px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-40 {
	margin-top: 40px;
}

.mt-45 {
	margin-top: 45px;
}

.mt-50 {
	margin-top: 50px;
}

.mt-60 {
	margin-top: 60px;
}

.mt-80 {
	margin-top: 80px;
}

.mt-140 {
	margin-top: 140px;
}

.mt-160 {
	margin-top: 160px;
}

.mt-184 {
	margin-top: 184px;
}

.mt-330 {
	margin-top: 330px;
}

.mt-410 {
	margin-top: 410px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-16 {
	margin-bottom: 16px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-28 {
	margin-bottom: 28px;
}

.mb-20-40 {
	margin-bottom: 20px;
}

@media (max-width: $media1200) {
	.mb-20-40 {
		margin-bottom: 40px;
	}
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-50 {
	margin-bottom: 50px;
}

.mb-60 {
	margin-bottom: 60px;
}

.mb-80 {
	margin-bottom: 80px;
}

.mb-130 {
	margin-bottom: 130px;
}

.ml--4 {
	margin-left: -4px;
}

.ml-0 {
	margin-left: 0px;
}

.ml-5 {
	margin-left: 5px;
}

.ml-8 {
	margin-left: 8px;
}

.ml-10 {
	margin-left: 10px;
}

.ml-16 {
	margin-left: 16px;
}

.ml-20 {
	margin-left: 20px;
}

.ml-25 {
	margin-left: 25px;
}

.ml-28 {
	margin-left: 28px;
}

.ml-30 {
	margin-left: 30px;
}

.ml-30-0 {
	margin-left: 30px;
}

@media (max-width: 440px) {
	.ml-30-0 {
		margin-left: 0;
	}
}

.ml-40 {
	margin-left: 40px;
}

.ml-50 {
	margin-left: 50px;
}

.ml-100 {
	margin-left: 100px;
}

.ml--350 {
	margin-left: -350px;
}

.mr-5 {
	margin-right: 5px;
}

.mr-10 {
	margin-right: 10px;
}

.mr-16 {
	margin-right: 16px;
}

.mr-20 {
	margin-right: 20px;
}

.mr-30 {
	margin-right: 30px;
}

.mr-40 {
	margin-right: 40px;
}

.mr-50 {
	margin-right: 50px;
}

.mr-200 {
	margin-right: 200px;
}

// Other
.pointer {
	cursor: pointer;
}

.brad-15 {
	border-radius: 15px;
}

.uppercase {
	text-transform: uppercase;
}

@for $i from 1 through 10 {
	$s: $i * 10;

	.m-#{$s} {
		margin: $s + px !important;
	}
	.mt-#{$s} {
		margin-top: $s + px !important;
	}
	.mb-#{$s} {
		margin-bottom: $s + px !important;
	}
	.ml-#{$s} {
		margin-left: $s + px !important;
	}
	.mr-#{$s} {
		margin-right: $s + px !important;
	}

	.p-#{$s} {
		padding: $s + px !important;
	}
	.pt-#{$s} {
		padding-top: $s + px !important;
	}
	.pb-#{$s} {
		padding-bottom: $s + px !important;
	}
	.pl-#{$s} {
		padding-left: $s + px !important;
	}
	.pr-#{$s} {
		padding-right: $s + px !important;
	}
}

.m-auto {
	margin: auto !important;
}

.mt-auto {
	margin-top: auto !important;
}

.mb-auto {
	margin-bottom: auto !important;
}

.ml-auto {
	margin-left: auto !important;
}

.mr-auto {
	margin-right: auto !important;
}

.p-0 {
	padding: 0 !important;
}

.pt-0 {
	padding-top: 0 !important;
}

.pb-0 {
	padding-bottom: 0 !important;
}

.pl-0 {
	padding-left: 0 !important;
}

.pr-0 {
	padding-right: 0 !important;
}

.text-upper {
	text-transform: uppercase;
}

.count-block > div {
	flex: 1 1 0;
	padding-left: 10px;
	padding-right: 10px;
}

.backdropFilter15 {
	backdrop-filter: blur(15px);
}

@media (min-width: $media1023) {
	.noisePadding {
		padding-top: 200px;
	}
}

.react-tel-input {

	.special-label {
		display: none;
	}

}