.layout {
  max-width: 100%;
  width: 100%;
  background: #EFEFF1;
  z-index: 0;
  &--white-bg {
    background-color: white;
  }
}
.observer {
  position: absolute;
  top: 20px;
}