@import "styles/app.scss";
.download {
  width: 100%;
  overflow: hidden;
  height: 100%;
  max-width: 533px;

  @include mediaEx{
    max-width: 577px;
  }
  &--blue {
    min-height: 472px;
    min-width: 475px;
    @include bgGradient277;
    .download__agreement {
      color: #ffffff99;
    }
  }
  &--transparent {
    @include bgMatteGlassLight;
    .download__agreement {
      color: #ffffff99;
    }
  }
  &--white-on-blue {
    .download__agreement {
      color: $colorWhite;
    }
  }

  &__title {
    max-width: 225px;
    margin-bottom: 20px;

    @include mediaLg {
      max-width: 530px;
      margin-bottom: 10px;
    }
  }
  &__agreement {
    margin-top: 20px;
    margin-bottom: 30px;
    color: $colorGray;

    @include mediaLg {
      margin-bottom: 40px;
    }
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
