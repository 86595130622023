@import "styles/app.scss";

.button {
  background-color: $colorAccent;
  border-radius: 50%;
  border: none;
  max-width: 46px;
  max-height: 46px;
  min-width: 46px;
  min-height: 46px;
  color: $colorWhite;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &--dot {
    &::after{
      background-color: $colorAccent;
      border: 2px solid $colorWhite;
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      top: 0;
      right: 0;
      border-radius: 50%;
    }
  }
}
