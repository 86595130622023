@import "styles/app.scss";

.header {
  @media print {
    display: none;
  }
  &_selectWrapper {
    display: none;
  }

  &_requestACall {
    flex: none;
  }

  &_burgerButton {
    margin-left: 5px;
    @include mediaGt {
      display: none !important;
    }
  }
  &--white {
    background-color: $colorWhite;
    top: 0;
    height: 68px;
    padding-top: 8px;
    &::after{
      background-color: $colorWhite;
      height: 68px;
      width: 100vw;
      top: 0;
      left: 0;
    }
    @include mediaLg {
      padding-top: 20px;
      height: 80px;
    }
  }
}

.selectScrolledMenu {
  top: calc(100% + 2px) !important;
  border-radius: 0 0 16px 16px !important;
  background: rgba(34, 54, 74, 0.5) !important;
  backdrop-filter: blur(15px) !important;

  @include mediaGt {
    top: calc(100% + 10px) !important;
  }
}

@include mediaGt {
  .header {
    &_nav {
      display: flex;
      align-items: center;
      gap: 30px;
    }

    &_selectWrapper {
      display: flex !important;
      justify-content: space-between;
      gap: 8px;
      @include p3;
    }
  }
}

@media (min-width: $screenXx) {
  .header {
    &_nav {
      gap: 35px;
    }
  }
}

@media (max-width: $screenSm) {
  .header_nav {
    svg {
      transition: 0.25s transform;
      transform-origin: left center;
    }

    &_scrolled {
      svg {
        transform: scale(0.95) translateX(3px);
      }
    }
  }
}

@include mediaLg {
  .header_nav {
    gap: 32px;
  }
  .header__white {
    background-color: $colorWhite;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 0;
    height: 80px;
  }
}
