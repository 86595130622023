@import "styles/app.scss";

.control {
  border: none !important;
  border-radius: 0 !important;
  background: none !important;
  min-height: 22px !important;
  box-shadow: none !important;
  cursor: pointer !important;
  caret-color: transparent !important;
  font-size: 16px !important;
  width: 100%;

  &.theme-light {
    --primary-color: #{$colorWhite};
  }

  &.theme-dark {
    --primary-color: #{$colorAccent};
  }
}

.dropdownIndicator {
  padding: 0 !important;
  color: var(--primary-color) !important;
  transition: 0.25s transform !important;

  &.open {
    transform: scaleY(-1);
  }
}

.indicatorSeparator {
  display: none;
}

.placeholder {
  color: var(--primary-color) !important;
}

.valueContainer {
  padding-right: 0 !important;
}

.singleValue {
  @include p3(false);
  display: flex;
  align-items: center;
  width: min-content;
  height: 22px !important;
  font-weight: bold !important;
  color: var(--primary-color) !important;
  grid-area: 1/1/2/3;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: hsl(0, 0%, 20%);
  margin-left: 2px;
  margin-right: 2px;
}

.menu {
  min-width: 100% !important;
  width: max-content !important;
  padding: 8px 0 !important;
  background: rgba(255, 255, 255, 0.2) !important;
  backdrop-filter: blur(15px) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0 51px 64px 0 !important;
  border-radius: 16px !important;
  animation: appear 0.2s ease-in-out;
}

.option {
  font-weight: bold !important;
  min-width: min-content !important;
  line-height: 1.2 !important;
  color: var(--primary-color) !important;
  cursor: pointer !important;
  text-align: left;
  padding-left: 16px !important;
  padding-right: 32px !important;
  background: transparent !important;

  &:hover {
    opacity: 0.8 !important;
  }

  &.selected {
    text-decoration: underline !important;
  }
}

.mobileMenu {
  min-width: 100% !important;
  width: max-content !important;
  padding: 16px 40px 0 16px !important;
  background: $colorLightGray !important;
  backdrop-filter: blur(10px) !important;
  box-shadow: rgba(0, 0, 0, 0) 0 51px 64px 0 !important;
  border: none !important;
  border-radius: 16px !important;
  animation: appear 0.2s ease-in-out;
  margin-bottom: 0 !important;
}

.mobileMenuOption {
  color: $colorAccent !important;
  cursor: pointer !important;
  background: transparent !important;
  padding: 0 !important;
  padding-bottom: 16px !important;
  @include p2(false);

  &:hover {
    opacity: 0.8 !important;
  }

  &.selected {
    text-decoration: underline !important;
  }
  &--light {
    color: $colorWhite !important;
  }
}

.selectWrapper {
  position: relative;
}

.dropdown {
  &__container {
    background-color: white !important;
    border: 1.6px solid $colorDarkWhite !important;
    border-radius: 30px !important;
    height: 48px;
    display: flex;
    padding-inline: 18px;
    position: relative;
    min-width: min-content;
    &--selected::after {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $colorAccent;
      right: 20px;
      top: 0;
      translate: 50% -50%;
      border: 2px solid $colorWhite;
    }
    &--light {
      border-color: $colorWhite !important;
      background: rgba(255, 255, 255, 0.1) !important;
    }
    &--dark {
      &:hover {
        background-color: $colorWhite !important;
        color: $colorAccent !important;
        border-color: $colorAccent !important;
      }
    }
    &--open {
      border-color: $colorAccent !important;
    }
    &--md {
      height: 46px !important;
    }
    &--sm {
      height: 40px !important;
    }

    @include mediaEx {
      border-width: 2px !important;
    }
  }
  &__menu {
    background-color: white !important;
    border: 1px solid #d4d4d4 !important;
    border-radius: 20px !important;
    padding: 20px 16px 16px 16px !important;
    left: 0;
    z-index: 101 !important;

    &--light {
      background: rgba(255, 255, 255, 0.2) !important;
      box-shadow: 0px 51px 64px 0px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(15px);
      margin-top: 12px !important;
      border: 1px solid transparent !important;
    }
  }
  &__placeholder {
    @include p2(false);
    &--light {
      color: $colorWhite !important;
    }
  }
  &__indicator--light {
    color: $colorWhite !important;
  }
  &__menuList {
    display: flex;
    flex-direction: column;
    gap: 6px;
    @include p2(false);
  }
  &__option {
    @include p2(false);
    height: 29px;
    display: flex !important;
    align-items: center !important;
    padding: 0 !important;
    gap: 10px;
    &--disabled,
    &--disabled:hover {
      opacity: 0.5 !important;
      cursor:
        url("/icons/not-allowed-cursor.svg") 0 0,
        not-allowed !important;
    }

    &.selected {
      text-decoration: none !important;
    }
  }
  &__valueContainer {
    padding: 0 !important;
  }
}
