@import "styles/app.scss";
.request {
  width: 100%;
  overflow: hidden;
  height: 100%;
  position: relative;

  &--blue {
    min-height: 472px;
    min-width: 475px;
    @include bgGradient277;
    .request__agreement {
      color: #ffffff99;
    }
  }
  &--transparent {
    .request__agreement {
      color: #ffffff99;
    }
  }
  &--accent {
    color: $colorAccent;
    display: flex;
    flex-direction: column;
    background: $colorWhite;
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      inset: 0;
      z-index: 0;
      background-color: rgba($colorLightGray, 0.1);
      pointer-events: none;
    }
    * {
      z-index: 1;
    }
    .request__title {
      color: $colorAccent;
    }
    .request__agreement {
      color: $colorAccent;
    }
  }
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    z-index: -1;
    height: 100%;
    @include bgMatteGlassLight;
  }

  &__input {
    margin-top: 10px;
  }

  &__title {
    max-width: 300px;
    color: $colorWhite;
    margin-bottom: 16px;
  }
  &__agreement {
    margin-top: 16px;
    margin-bottom: 20px;
    color: $colorGray;

    @include mediaLg {
      margin-top: 30px;
    }

    &--bottom {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }

  &__button {
    margin-top: 30px;
  }
  &__buttonAndAgreement {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    &--reverse {
      flex-direction: column-reverse;
      .request__button {
        margin-top: 40px;
      }
      .request__agreement {
        margin-top: 25px;
        margin-bottom: 0;
      }
    }
  }
}
