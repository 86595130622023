@import "styles/app.scss";

.header {
  position: fixed;
  top: 8px;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  margin: auto;
  height: 60px;
  width: 100%;

  &-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
    position: relative;
    padding: 10px 0 10px 5px;
    transition: .25s background, .25s padding;
    border-radius: 30px;

    &_scrolled {
      padding: 10px 10px 10px 20px;
      background: rgba(34, 54, 74, 0.5);
      backdrop-filter: blur(15px);
    }

    @include mediaXx {
      &_scrolled {
        padding: 16px 8px 16px 18px;
      }
    }
  }
}

// MainMenu
.mainMenu-wrapper {
  display: none;
}

.dropSales {
  z-index: 5;
  backdrop-filter: blur(15px) !important;
  -webkit-backdrop-filter: blur(15px) !important;
}

.dropService {
  z-index: 5;
  backdrop-filter: blur(15px) !important;
  -webkit-backdrop-filter: blur(15px) !important;
}

@media (min-width: $screenMd) {
  .header {
    top: 8px;
  }
}

@media (min-width: $screenLg) {
  .header {
    top: 20px;
  }
}

@media (min-width: $screenGt) {
  .header {
    color: var(--headerColor);

    &-wrapper {
      overflow: visible;

      &-mobileMenu {
        display: none !important;
      }
    }

    // MainMenu
    .mainMenu-wrapper {
      display: flex;
      justify-content: space-between;

      nav {
        display: flex;
        align-items: center;
        gap: 20px;

        .nav-item {
          text-decoration: none;
          display: flex;
          align-items: center;
          position: relative;
          cursor: pointer;

          p {
            color: var(--headerColor);
            margin: 0;
            white-space: nowrap;
            letter-spacing: -0.03em;
          }

          img {
            width: 15px;
            height: 15px;
            margin-left: 4px;
          }

          .dropdownContent {
            min-width: 180px;
            display: flex;
            gap: 16px;
            flex-direction: column;
            align-items: flex-start;
            position: absolute;
            top: 52px;
            left: 0;
            padding: 16px 16px 8px 16px;

            a {
              text-decoration: none;
              color: var(--headerColor);
              font-size: 12px;
              padding: 0;

              &:hover {
                color: $colorDarkWhite;
              }
            }
          }
        }
      }
    }
  }
}
