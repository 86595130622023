@import "styles/app.scss";

.messengers {
    display: flex;
    gap: 26px;
    align-items: center;



    &__link {
        position: relative;
    }

    &__shadow {
        display: none;
        
    }

    &__link:hover &__shadow {
        @include mediaMd {
            display: block;
            width: 48px;
            height: 48px;
            box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.15);
            inset: 0;
            border-radius: 50%;
            position: absolute;
        }
        
    }
}