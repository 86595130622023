@import "styles/app.scss";

.questionModal {
    width: 310px;
    height: min-content;
    padding: 40px 16px;
    overflow: visible;
    overscroll-behavior: none;
    position: relative;
    background-color: $colorWhite;
    margin: 0 auto;
    
    @include mediaMd {
      width: 612px;
      min-height: min-content;
      height: auto;
      padding: 40px;
    }

    &__leaf {
        display: none;
      
      @include mediaMd {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 427px;
        height: 600px;
      }
    
    }
    
    &__leafImg {
        width: 427px;
        height: 600px;
      }

      &__content {
        width: 100%;
        max-width: 352px;
        @include mediaMd {
            width: 352px;
        }
      }
    
    &__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    max-width: 235px;
    white-space: pre-wrap;
    
    @include mediaMd {
        max-width: 100%;
    }
    }

    
}

