@import 'styles/app.scss';

.separateHeaderModal {
    background: $colorWhite;
    width: 100%;
    height: 100%;
    
  
    @include mediaLg {
        width: 500px;
        max-width: 500px;
        margin: 0 auto;
        background: transparent;
    }
  
    &__content {
        width: 100%;
        height: 100vh;
        display: flex;
        padding-inline: 16px;
        justify-content: center;
        flex-direction: column;
        gap: 0;
  
   
      @include mediaLg {
        width: 500px;
        margin: 0 auto;
        gap: 16px;
      }
  
    }
  
    &__backBtn {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
  
      @include mediaLg {
        display: none;
      }
    }
  
    &__header {
      position: relative;
      padding: 25px 0;
      background: $colorWhite;
      text-align: center;
      @include p1;
      border-bottom: 1px solid $colorDarkWhite;

      @include mediaLg {
        padding: 18px 0;
        font-size: 22px;
        line-height: 36px;
        border: none;
      }
    }
  
    &__body {
      padding-top: 16px;
      padding-bottom: 40px;
      position: relative;
      flex: 1;
      background: $colorWhite;
      max-height: 100%;
      overflow-y: scroll;
      @include scrollDisable;
      
  
      @include mediaLg {
        padding-top: 40px;
        padding-inline: 40px;
        height: 616px;
        max-height: 616px;
      }
    }
  }
  
  .closeBtn {
    display: none;
    @include mediaLg {
      display: flex;
      position: absolute;
      top: -40px;
      right: -40px;
    }
  }

  .scroller {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    padding-bottom: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent, $colorWhite 40%);
    height: 50px;
    padding-top: 6px;
    cursor: pointer;
  
  
    &.hidden {
      display: none;
    }
  
    &::before {
      position: relative;
      display: block;
      width: 8px;
      height: 8px;
      border-left: 2px solid $colorAccent;
      border-bottom: 2px solid $colorAccent;
      transform: rotate(-45deg);
      transform-origin: center;
      content: "";
    }
  }

