@import "styles/app.scss";
@mixin grayInput {
  width: 100%;
  border-radius: 30px;
  border: 1.6px solid $colorDarkWhite;
  height: 100%;
  padding: 12px 90px 12px 20px;
  @include p3-input;
  &,
  &::placeholder {
    color: $colorAccent;
  }
  @include mediaEx {
    border-width: 2px;
  }
}

.input {
  padding: 20px 10px;
  max-width: 330px;
  width: 100%;
  border: unset;
  background: transparent;
  outline: none;
  caret-color: $colorDarkWhite;
  color: $colorAccent;
  @include p3-input;
  caret-color: $colorAccent;
  transition:
    color 0.2s,
    border-color 0.2s;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 174, 255, 0) 50%,
      rgba(255, 255, 255, 0) 51%,
      rgba(0, 174, 255, 0) 100%
    );
  }
  &::-webkit-autofill::first-line {
    font-size: 24px !important;
  }

  &::placeholder {
    @include p3;
    color: #ffffff99;
    transition: color 0.1s;
  }
  &:focus::placeholder {
    color: transparent !important;
  }
  &--blue,
  &--transparent {
    caret-color: #ffffff99;
    color: $colorWhite;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus &:-webkit-autofill {
      -webkit-text-fill-color: $colorWhite;
    }
  }
  &--accent {
    caret-color: $colorAccent;
    color: $colorAccent;
    &::placeholder {
      color: $colorAccent;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus &:-webkit-autofill {
      -webkit-text-fill-color: $colorAccent;
    }
  }
  &--white {
    color: $colorAccent;
    &::placeholder {
      color: $colorGray;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus &:-webkit-autofill {
      -webkit-text-fill-color: $colorAccent;
    }
  }
  &--white-on-blue {
    color: $colorWhite;
    caret-color: $colorWhite;
    &::placeholder {
      color: $colorWhite !important;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus &:-webkit-autofill {
      -webkit-text-fill-color: $colorWhite;
    }
  }

  &__wrapper {
    position: relative;
    // margin-bottom: 32px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d4d4d4;

    @include mediaLg {
      // margin-bottom: 27px;
    }

    &--error {
      border-color: #ae1616;
    }

    &--valid {
      border-color: #4c8832;
    }
    &--accent {
      border-color: $colorAccent;
    }
    &--blue {
      border-bottom: 1px solid #ffffff99;
      transition: border-color 0.2s;
      &:has(input:active, input:focus) {
        border-bottom: 1px solid $colorWhite;
      }
      &.input__wrapper--error {
        color: $colorErrorLight;
        border-color: $colorErrorLight;
      }
      > .input__error {
        color: $colorErrorLight;
      }
    }
    &--white {
      border-bottom: 1px solid $colorDarkWhite;
      transition: border-color 0.2s;
      &:has(input:active, input:focus) {
        border-bottom: 1px solid $colorAccent;
      }
      &.input__wrapper--error {
        color: $colorError;
        border-color: $colorError;
      }
      > .input__error {
        color: $colorError;
      }
    }
    &--white-on-blue {
      border-bottom: 1px solid $colorDarkWhite;
      transition: border-color 0.2s;
      &:has(input:active, input:focus) {
        border-bottom: 1px solid $colorWhite;
      }
      &.input__wrapper--error {
        color: $colorError;
        border-color: $colorError;
      }
      > .input__error {
        color: $colorError;
      }
    }
  }
  &__error {
    position: absolute;
    color: #ae1616;
    bottom: -15px;
    translate: 0 50%;
    @include p4;
  }
}

.search {
  &__input {
    border-width: 1px !important;
  }
}

.search,
.price {
  &__wrapper {
    max-width: 100%;
    width: 100%;
    position: relative;
    height: 46px;
    @include mediaLg {
      max-width: 545px;
    }
  }
  &__input {
    @include grayInput;
    font-weight: 700;
    font-size: 14px !important;
    line-height: 20px !important;
  }
  &__icons {
    display: flex;
    gap: 10px;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 0;
    translate: -50% -50%;
    width: 54px;
    justify-content: flex-end;
  }
  &__icon {
    cursor: pointer;
  }
  &__reset {
    background-color: transparent;
    border: none;
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    path {
      stroke: $colorAccent;
    }
  }
  &__popup {
    position: absolute;
    content: "";
    bottom: -20px;
    left: 0;
    border: 1px solid $colorBorder;
    border-radius: 30px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 5;
    background-color: $colorWhite;
    translate: 0 100%;
    color: $colorGray;
    /* settings button width and gap */
    width: calc(100% + 10px + 46px);
    @include mediaLg {
      width: 100%;
    }

    button {
      width: min-content;
      white-space: nowrap;
    }
  }
}
.price {
  &__wrapper {
    position: relative;
    height: 38px;
    @include mediaLg {
      max-width: 160px;
      width: 100%;
    }
  }
  &__input {
    padding-right: 40px;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button,
    & {
      -webkit-appearance: none;
      margin: 0;
      -moz-appearance: textfield !important;
    }
    &::placeholder {
      color: $colorGray;
    }
  }
  &__icon {
    cursor: default;
    position: absolute;
    width: 26px;
    height: 26px;
    right: 10px;
    top: 50%;
    translate: 0 -50%;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  &--disabled {
    *,
    & {
      pointer-events: none;
      touch-action: none;
    }
  }
  &__inputWrapper {
    width: 26px;
    height: 26px;
    position: relative;
    display: block;
    &--lg {
      svg {
        width: 18px;
        height: 18px;
      }
    }
    &--md {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  &__input {
    position: absolute;
    appearance: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    &:checked ~ .checkbox__checkmark {
      z-index: 2;
      &--light {
        background-color: $colorWhite;
        path {
          display: block;
          stroke: $colorAccent;
        }
      }
      &--dark {
        background-color: $colorAccent;
        border-color: $colorWhite;
        path {
          display: block;
          stroke: $colorWhite;
        }
      }
    }
    &:disabled {
      cursor:
        url("/icons/not-allowed-cursor.svg") 0 0,
        not-allowed;
    }
  }

  &__checkmark {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    pointer-events: none;
    touch-action: none;
    border-radius: 4px;
    translate: -50% -50%;
    path {
      display: none;
    }
    &--dark {
      background-color: transparent;
      border: 1.6px solid $colorDarkWhite;
    }
    &--light {
      background-color: transparent;
      border: 1.6px solid $colorWhite;
    }
  }
  &__label {
    padding-left: 10px;
    cursor: pointer;
    transition: color 0.2s;
    &:hover,
    &:focus {
      color: $colorAccent;
    }
    &--unchecked {
      color: $colorGray;
    }
    &--disabled {
      color: $colorDarkWhite;
      cursor:
        url("/icons/not-allowed-cursor.svg") 0 0,
        not-allowed;

      &:hover,
      &:focus {
        color: $colorDarkWhite;
      }
    }
  }
}
.button {
  position: relative;
  display: flex !important;
  align-items: center;
  padding: 10px 20px !important;
  span {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
  }
  p {
    padding-left: 0;
  }
  &:hover {
    background-color: $colorAccent;
    p {
      color: $colorWhite;
    }
  }
  &--active {
    background-color: $colorAccent !important;
    border-color: transparent !important;
    p {
      color: $colorWhite;
    }
    &:hover {
      background-color: $colorAccent;
      p {
        color: $colorWhite;
      }
    }
  }
  &--disabled {
    background-color: $colorLightGray !important;
    &:hover {
      background-color: $colorLightGray !important;
      border-color: $colorGray !important;
    }
    *,
    & {
      color: $colorGray !important;
      cursor:
        url("/icons/not-allowed-cursor.svg") 0 0,
        not-allowed;
    }
  }
  &__input {
    position: absolute;
    inset: 0;
    appearance: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  &__label {
    cursor: pointer;
    line-height: 20px;
    &--checked {
      color: $colorWhite;
    }
    &--unchecked {
      color: $colorGray;
    }
  }
}

.labelledCheckbox {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  user-select: text;
}
