@import "styles/app.scss";

.mobileMainMenu {
  top: 0;
  left: 0;
  margin: 0;
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
  height: 100vh;
  box-shadow: 5px 5px 10px 0 rgba(68, 68, 68, 0.15);
  background: $colorWhite;
  transform: translateX(100%);
  transition: all 0.25s;
  z-index: 5;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 36px;
  padding-bottom: 50px;

  overflow-y: scroll;
  overflow-x: hidden;
  @include scrollDisable;

  @include mediaGt {
    display: none;
  }

  &_active {
    transform: translateX(0);
  }

  &_section {
    display: flex;
    width: 100vw;
    translate: 0 0;
    transition: translate 0.3s ease;
    @include scrollDisable;
    > div {
      min-width: 100%;
    }

    &--open {
      translate: -100% 0;

      .menu__additional {
          opacity: 1;
        }
    }
    
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $colorDarkWhite;
    padding: 18px 18px 20px 16px;
    position: -webkit-sticky; 
    position: sticky;
    top: 0;
    z-index: 6;
    background: $colorWhite;

    min-width: 100%;

    &_right {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &_button {
      margin-left: 12px;
    }
  }

  &_item {
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 10px 0;

    p {
      color: $colorAccent;
      margin: 0;
    }

    img {
      width: 22px;
      height: 22px;
      rotate: -90deg;
      margin-left: 8px;
    }
  }

  .dropdownContent {
    display: none;
  }

  &:hover {
    .dropdownContent {
      position: absolute;
      max-width: 320px;
      z-index: 6;
      left: 20px;
      display: flex;
      flex-direction: column;
      text-align: left;
      box-shadow: 0 0 15px 0 rgba(68, 68, 68, 0.15);
      border-radius: 12px !important;
    }

    .dropdownContent a {
      @include p3;
      padding-inline: 16px;
      min-width: 200px;
      text-decoration: none;
      display: block;
      cursor: pointer;
      background: $colorWhite;
    }

    .dropdownContent a:hover {
      color: $colorAccent;
      cursor: pointer;
      position: relative;
      z-index: 6;
    }

    .dropdown:hover .dropdownContent {
      display: block;
    }
  }
}

.mobileMainMenu-header-right {
  button {
    margin-left: 32px;
  }
}

.menuBtn {

  .logo {
    text-decoration: none;
  }
}

.menu {
  &__nav {
    height: min-content;
    padding-top: 36px;
    padding-left: 16px;
    padding-right: 18px;
  }

  &__additional {
    opacity: 0;
    transition: opacity 0.2s;
    position: relative;
    
  }

  &__additionalTop {
    display: flex;
    padding: 18px;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $colorDarkWhite;
  }

  &__selectedTitle {
    color: $colorAccent;
  }

  &__selectedList {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding-left: 16px;
    padding-right: 18px;
  }
  &__bottomItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__bottomButton {
    margin-top: 5px;
  }
}
