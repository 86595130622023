@import "variables";

@media (min-width: $media0) {

	.h2Subtitle {
		font-size: 30px;
		font-family: $fontTsars;
		color: $colorAccent;
		line-height: 38px;
		letter-spacing: 0.14px;
	}

	.h3TitleText {
		font-size: 18px;
		font-family: $fontManrope;
		font-weight: 600;
		color: $colorAccent;
		line-height: 28px;
		letter-spacing: 0.14px;
	}

	.text500 {
		font-size: 14px;
		font-family: $fontManrope;
		font-weight: 500;
		color: $colorAccent;
		line-height: 20px;
		letter-spacing: 0.14px;
	}
}

@media (min-width: $media1440) {

	.h2Subtitle {
		font-size: 3.333vw;
		font-family: $fontTsars;
		color: $colorAccent;
		line-height: 4.306vw;
	}

	.h3TitleText {
		font-size: 1.806vw;
		font-family: $fontManrope;
		font-weight: 600;
		color: $colorAccent;
		line-height: 2.5vw;
	}

	.text500 {
		font-size: 1.25vw;
		font-family: $fontManrope;
		font-weight: 500;
		color: $colorAccent;
		line-height: 2.083vw;
	}
}

.fz-12 {
	font-size: 12px;
	line-height: 20px;
}


.ls-012 {
	letter-spacing: 0.12px;
}
