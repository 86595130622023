@import "styles/app.scss";

.feedBackModal {
    width: 310px;
    height: min-content;
    min-height: 612px;
    padding: 40px 20px;
    overflow: visible;
    overscroll-behavior: none;
    position: relative;
    background-color: $colorWhite;
    margin: 0 auto;
    
    @include mediaMd {
      width: 612px;
      min-height: min-content;
      height: auto;
      padding: 40px;
    }

    &__leaf {
        display: none;
        pointer-events: none;
      
      @include mediaMd {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 427px;
        height: 600px;
      }
    
    }
    
    &__leafImg {
        width: 427px;
        height: 600px;
      }

      &__content {
        width: 100%;
        max-width: 352px;
        @include mediaMd {
            width: 352px;
        }
      }
    
    &__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    max-width: 235px;
    span {
      white-space: pre-wrap;
    }
    
    @include mediaMd {
        margin-bottom: 30px;
        max-width: 100%;
        span {          
           white-space: unset;
        }
    }
    }

    &__text {
    margin-bottom: 10px;
    color: $colorGray;
    }

    &__button {
    width: 100%;
    max-width: 352px;
    }

    &__messengers {
        position: relative;
        z-index: 100;
        margin-bottom: 30px;
    }

}

