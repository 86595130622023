@import "styles/app.scss";

.feedBackModal {
    width: 100vw;
    height: 100vh;
    min-height: 100dvh;
    padding-top: 20px;
    padding-inline: 16px;
    overflow: hidden;
    overscroll-behavior: none;
    position: relative;
    
    @include mediaLg {
      width: 100%;
      height: min-content;
      min-height: min-content;
      padding: 40px 16px;
    }
}

.feedbackAnimation {
    display: none;
  
  @include mediaLg {
    display: block;
    position: absolute;
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
    top: -100px;
    right: -200px;
    width: 607px;
    height: 1112px;
  }

  @include mediaEx {
    right: -150px;
  }
}

.feedbackFlowerRight {
  transform: scale(1.04);
  width: 475px;
  height: 870px;
}

.feedbackBg {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
}

.feedbackContent {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 19px;

  @include mediaSm {
    margin-top: 0;
  }
  @include mediaLg {
    flex-direction: row;
    gap: 30px;
  }
  @include mediaGt {
    align-items: center;
    gap: 200px;
  }
}

.feedbackTitle {
  margin-bottom: 20px;
  max-width: 250px;

  @include mediaSm {
    max-width: 90%;
  }
  @include mediaMd {
    max-width: 100%;
  }
}

.feedBackText {
  color: $colorGray;
  max-width: 100%;
 
}

.feedBackFormWrapper {
  align-self: flex-start;

  @include mediaMd {
    align-self: center;
  }
 
}

.feedBackForm {
    width: 100%;
  margin-top: 10px;
  min-height: unset;
  min-width: unset;
  padding-bottom: 40px;
  border-bottom: 1px solid $colorDarkWhite;

 

  @include mediaLg {
    padding-bottom: 0;
    border-bottom: none;
  }
  @include mediaGt {
    max-width: 585px;
  }

  @include mediaXx {
    max-width: 700px;
  }

  button {
    width: 100%;
    @include mediaLg {
      width: fit-content;
    }
  }
}

.messengerDesctop {
    display: none;
    @include mediaLg {
        display: block;
    }
}

.messengerMobile {
    display: block;
    text-align: center;

    @include mediaLg {
        display: none;
    }

    &__title {
        max-width: 233px;
    }

    &__cardList {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 36px;
        margin-top: 23px;
    
        @include mediaLg {
            display: none;
        } 
    
    }

    &__card {
        width: 48px;
        height: 48px;
        flex: none;
        color: $colorGray;

        svg {
            width: 48px;
            height: 48px; 
        }
    }
}


