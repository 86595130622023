@import "styles/app.scss";

.main {
  display: flex;
  position: relative;
  z-index: 100;

  &.size-m {
    gap: 20px;
    svg {
      height: 20px;
      width: auto;
    }
  }

  &.size-l {
    gap: 48px;
    svg {
      height: 48px;
      width: auto;
    }
  }

  &.blue svg {
    &:hover path {
      filter: brightness(1.2);
    }

    path {
      fill: #4074A5;
      transition: .25s filter;
    }
  }
}
  
