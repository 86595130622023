@import "variables";
@import "media";

@media (min-width: $media0) {
  .palmsTransparent-bg {
    position: absolute;
    left: -930px;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
  }

  .disabled {
    opacity: 0.4;
    background-color: transparent;
    border: none;
  }
}

// ShareContent
.shareContent {
  position: relative;
  background: $colorLightGray;
  padding-bottom: 40px;

  .bgOpacity {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-height: 188px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  h2 {
    margin-bottom: 20px;
  }
}

@media (min-width: $media475) {
  .palmsTransparent-bg {
    position: absolute;
    left: -730px;
  }

  // FeedBackBlock
  .feedBackBlock {

    .fiveStepsSlider-wrapper {
      h3 {
        padding-top: 28px;
      }
    }
  }
}

@media (min-width: $screenSm) {
  .palmsTransparent-bg {
    position: absolute;
    left: -560px;
  }
}

@media (min-width: $screenMd) {
  .palmsTransparent-bg {
    position: absolute;
    left: 0;
  }
}

@media (min-width: $media1440) {
  .palmsTransparent-bg {
    //left: 600px;
    scale: 1.26;
  }
}
