@import "styles/app.scss";

.control {
  border: none !important;
  border-bottom: 1px solid #DCDCDC !important;
  border-radius: 0 !important;
  background: none !important;
  min-height: 64px !important;
  box-shadow: none !important;
  cursor: pointer !important;
  caret-color: transparent !important;
  @include p2;
  padding-left: 10px;

  div {
    padding-inline: 0;
    padding-top: 0;
  }

  &.error {
    border-color: #ae1616 !important;
  }
}

.dropdownIndicator {
  padding: 0 !important;
  color: $colorAccent !important;
  transition: .25s transform !important;

  &.open {
    transform: scaleY(-1);
  }
}

.indicatorSeparator {
  display: none;
}

.placeholder {
  color: $colorAccent !important;
  margin-inline: 0 !important;
}

.singleValue {
  display: flex;
  align-items: center;
  height: 54px !important;
}

.menu {
  background: rgba(255, 255, 255, 0.2) !important;
  backdrop-filter: blur(15px) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0 51px 64px 0 !important;
  border-radius: 16px !important;
  animation: fade--in .2s ease-in-out;
}

@keyframes fade--in {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.option {
  cursor: pointer !important;
  background: transparent !important;
  @include p3;

  &:hover {
    opacity: .8 !important;
  }

  &.selected {
    font-weight: bold !important;
    color: $colorAccent !important;
  }
}

.selectWrapper {
  position: relative;
}

.errorText {
  position: absolute;
  color: #ae1616;
  bottom: -15px;
  translate: 0 50%;
  @include p4;
}