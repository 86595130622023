@import "styles/app.scss";

.popup {
  position: fixed;
  z-index: 10000;
  animation: appear 0.3s;
  width: 100%;
  height: 100%;
  top: 0;
  @media (max-width: 995px) {
    .popup__overlay {
      background: #ececef;
      opacity: 1;
    }
    .popup__content {
      max-width: 100vw;
      max-height: 100vh;
      padding: 0;
    }
  }

  &__container {
    position: relative;
  }

  &__content {
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    max-width: 350px;
    height: 100%;
    max-height: 100vh;
    padding: 16px;
    position: fixed;
    overflow-y: scroll;
    z-index: 10001;
    margin: 0 auto;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    width: 100%;
    @include mediaSm {
      max-width: 568px;
    }
    @include mediaMd {
      max-width: 748px;
    }
    @include mediaLg {
      max-width: 966px;
    }
    @include mediaGt {
      max-width: 1160px;
    }
    @include mediaEx {
      max-width: 80%;
    }
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0);
    transition: opacity 0.2s;
    opacity: 0.5;
    top: 0;
    left: 0;
    z-index: 9000;
  }

  &__close {
    @include modalCloseBtn;
    z-index: 10001;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: $colorAccent;
    box-shadow: 0 51px 64px 0 rgba(0, 0, 0, 0.25);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    cursor: pointer;
    circle {
      stroke-width: 1.6px;
    }

    @include mediaLg {
      right: 30px;
      top: 20px;
      color: $colorWhite;
    }
    @include mediaGt {
      right: 40px;
    }
    @include mediaEx {
      right: 8%;
      color: $colorWhite;
      circle {
        stroke-width: 2px;
      }
    }
  }
  &__close--cross {
    > div {
      width: auto;
      height: auto;
    }
  }

  &.fillFeedBackNew {
    .popup__overlay {
      background: #000;
      opacity: 0.5;
    }

    .popup__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 310px;
      max-height: 100vh;
      overflow-y: hidden;
      padding: 0;
      

      @include mediaMd {
        max-width: 700px;
      }

    }

    .popup__modalContainer {
      position: relative;
      overflow: scroll;
      @include scrollDisable;

      @include mediaLg {
        padding-top: 40px;
      }
    }

    .popup__close {
      top: 20px;
      right: 20px;
      @include mediaMd {
        top: 20px;
        right: 60px;
      }
      @include mediaLg {
        color: $colorWhite;
        top: 0;
        right: 0;
      }
    }
    
  }

  &.fillOverlay {
    .popup__overlay {
      background: #ececef;
      opacity: 1;
    }
  }

  &.fullScreen {
    .popup__content {
      max-width: 100vw;
      max-height: 100vh;
      padding: 0;
    }
  }

 

  &__content--flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content--video {
    top: 55px;
    translate: -50% 0;
    max-width: calc(100% - 32px);
    @include mediaEx {
      max-width: calc(100% - 96px);
    }
    aspect-ratio: 1344 / 700;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: visible;

    ~ .popup__close {
      right: 42px;
      top: 80px;
      @include mediaMax($screenSm) {
        scale: 0.8;
      }
      @include mediaGt {
        right: 82px;
        top: 90px;
      }
    }
  }

  &__content--filter {
    padding: 0;
    max-width: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
    translate: 0;
    height: 100%;

    .popup__close {
      right: 19px;
      top: 16px;
      box-shadow: none;
      color: $colorAccent;
      @include mediaMax($screenSm) {
      }
      @include mediaMd {
        right: 40px;

        top: 25px;
        translate: -50% 0;
      }
      @include mediaLg {
        top: calc(27px + 30px);
      }
    }
    @include mediaGt {
      padding: 27px 16px 27px;
      max-width: 1644px;
      left: 50%;
      translate: -50% 0%;
      overflow: unset;
    }
  }

  &__content--map {
    padding: 0 40px;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    top: 0;
    left: 0;
    translate: 0;
    overflow: hidden;
    padding-bottom: 40px;

    .popup__close {
      right: 19px;
      top: 16px;
      box-shadow: none;
      color: $colorAccent;

      @include mediaMax($screenSm) {
        scale: 0.8;
      }
      @include mediaLg {
        right: 50px;
        top: 40px;
      }
    }

    @include mediaMd {
      max-width: 1006px;
      top: 0;
      left: 50%;
      translate: -50% 0;
    }
  }

  &__content--gallery {
    .popup__close {
      display: none;
      right: 16px;
      top: 20px;
      box-shadow: none;
      color: $colorAccent;

      @include mediaLg {
        display: block;
        right: 24px;
      }
    }
  }

  &__content--feedBack {
    overflow-y: hidden;
    padding: 100px 0;
    max-width: 90%;

    @include mediaEx {
      max-width: 70%;
    }
    .popup__close {
      @include mediaLg {
        top: 50px;
        right: 0;
      }

    }
  }

 
}
