@import "variables";

@media (min-width: $media0) {
  // VideoSlider
  .videoSlider {
    position: relative;
    z-index: 6;

    .sliderNext {
      position: absolute;
      bottom: 32px;
      right: 20px;
      z-index: 1;
      cursor: pointer;
    }

    .sliderPrev {
      position: absolute;
      bottom: 32px;
      right: 60px;
      z-index: 1;
      cursor: pointer;
    }

    .slick-track {
      height: 700px;
    }

    .slick-slider {
      position: relative;
      display: block;
      width: 100%;
    }

    .slick-arrow {
      position: absolute;
      z-index: 3;
    }

    .slick-arrow {
      position: absolute;
      bottom: 20px;
      right: 16px;
      width: 0;
      height: 0;
      padding: 0;
      text-indent: -9999px;
      background: transparent;
      border: none;
    }

    .slick-arrow:after {
      content: "";
      position: absolute;
      bottom: 20px;
      z-index: 2;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
    }
  }

  // OffersSlider
  .offersSlider {
    position: relative;

    margin: 0 auto;
    max-width: 359px;

    .slick-track {
      display: flex;
      gap: 16px;
    }
    .sliderNext {
      position: absolute;
      bottom: -30px;
      right: 40%;
      z-index: 1;
    }

    .sliderPrev {
      position: absolute;
      bottom: -30px;
      left: 40%;
      z-index: 1;
    }

    .slick-slider {
      position: relative;
      display: block;
      width: 100%;
    }

    .slick-arrow {
      position: absolute;
      z-index: 3;
    }

    .slick-arrow {
      position: absolute;
      bottom: 20px;
      right: 16px;
      width: 40px;
      height: 40px;
      padding: 0;
      text-indent: -9999px;
      background: transparent;
      border: none;
    }

    .slick-arrow:after {
      content: "";
      position: absolute;
      bottom: 20px;
      z-index: 2;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
    }
  }
}

@media (min-width: $media475) {
  // OffersSlider
  .offersSlider {
    .sliderNext {
      bottom: -40px;
    }

    .sliderPrev {
      bottom: -40px;
    }
  }
}

@media (min-width: $screenMd) {
  // OffersSlider
  .offersSlider {
    max-width: 100%;
    .slick-track {
      min-height: auto;
      gap: 20px;
      display: flex;
    }

    .sliderNext {
      right: -20px;
      bottom: 46%;
      border-radius: 50%;
      padding: 12px;
      border: 2px solid $colorWhite;
      backdrop-filter: blur(2px);
      transition: 0.2s all;
      @media (hover) {
        &:hover {
          background: rgba(255, 255, 255, 0.3);
        }
      }

      img {
        width: 14px;
        height: 10px;
      }
    }

    .sliderPrev {
      display: none;
    }
  }
}

@media (min-width: $media1400) {
  // OffersSlider
  .offersSlider {
    .sliderNext {
      right: 219px;
    }
  }
}

@media (min-width: $media1440) {
  // OffersSlider
  .offersSlider {
    .sliderNext {
      right: 235px;
      cursor: pointer;
    }
  }
}
