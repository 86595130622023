@import 'styles/app.scss';

.questionFormModal {
    background: $colorWhite;
    width: 100%;
    height: 100%;
    
    @include mediaLg {
        width: 550px;
        max-width: 550px;
        margin: 0 auto;
        height: 616px;
        max-height: 616px;
        
    }
  
    &__content {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        position: relative;
   
      @include mediaLg {
        width: 550px;
        height: 100%;
        margin: 0 auto;
      }
  
    }
  
    &__header {
      position: relative;
      padding: 20px 16px;
      background: $colorWhite;
      border-bottom: 1px solid $colorDarkWhite;

      @include mediaLg {
        padding: 0;
        border: none;
      }
    }
  
    &__body {
      position: relative;
      flex: 1;
      background: $colorWhite;
      max-height: 100%;
      overflow-y: scroll;
      @include scrollDisable;
     

    }

    &__footer {
        position: sticky;
        bottom: 0;
        padding: 40px 16px;
        background: $colorWhite;

        button {
            width: 100%;
            @include mediaSm {
                width: min-content;
            }
        }
        
        @include mediaLg {
            display: none;
        }
    }
  }

  .closeBtnMobile {
    @include mediaLg {
        display: none;
    }
  }
  
  .closeBtnDesctop {
    display: none;
    @include mediaLg {
      display: flex;
      position: absolute;
      top: -40px;
      right: -40px;
    }
  }

  .scroller {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    padding-bottom: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent, $colorWhite 40%);
    height: 50px;
    padding-top: 6px;
    cursor: pointer;
  
  
    &.hidden {
      display: none;
    }
  
    &::before {
      position: relative;
      display: block;
      width: 8px;
      height: 8px;
      border-left: 2px solid $colorAccent;
      border-bottom: 2px solid $colorAccent;
      transform: rotate(-45deg);
      transform-origin: center;
      content: "";
    }
  }

