.socials {
  stop {
    transition: 0.53s;
  }
  &:hover stop {
    &:first-child {
      stop-color: #4072a3;
    }
    &:last-child {
      stop-color: #7ea2bc;
    }
  }
  > a {
    height: 20px;
  }
}
