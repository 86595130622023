@import "styles/app.scss";

.footer {
  position: relative;
  background: $colorLightGray;
  padding: 16px;
  max-width: 100%;
  margin: 0 auto;
  @media print {
    display: none;
  }
  @media (min-width: 1921px) {
    max-width: 1624px;
  }

  &__container {
    background: $colorWhite;
    display: flex;
    height: fit-content;
    justify-content: center;
  }

  &_bottomMargin {
    margin-bottom: 90px;
  }
  &_noise {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include bgGrayWithNoise;
    z-index: 2;
  }

  &_ellipse {
    display: none;
  }

  &_leaves {
    display: none;
  }

  &__formWrapper {
    display: none;
  }

  &__form {
    padding: 65px 58px 77px 58px;
  }

  &__privacyPolicy {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    position: relative;
    z-index: 7;
    margin-top: 28px;
  }

  &__privacyPolicyLink {
    text-decoration: underline $colorGray !important;
    text-align: center;
    color: $colorGray;
    @include hover-gradient-text($colorGray);
  }

  &__contactsBlock {
    background: $colorWhite;
    width: 100%;
    padding: 60px 12px;
    overflow: hidden;
  }
  &__contactsRight {
    display: none;
  }

  &__contactWrapper {
    display: flex;
    justify-content: space-around;
    margin-top: 12px;
  }

  &__logotype {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 62px;

    img {
      width: 24px;
      height: 24px;
      margin-bottom: 4px;
      margin-right: 8px;
    }
  }

  &__contactsWrapper {
    justify-content: center;
  }

  &__contactsLeft {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
  }
  &__contactsRight {
    display: none;
  }

  &__contactMail {
    text-decoration: none;

    @include hover-gradient-text;
  }
  &__contact {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      p {
        @include hover-gradient-text;
        white-space: nowrap;
      }
    }
  }
  &__socials {
    margin-top: 38px;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 7;
  }

  &__divider {
    border-bottom: 1px solid $colorLightGray;
    margin: 28px 0;
    width: 100%;
  }

  &__copyRights {
    text-align: center;
    color: $colorGray;
  }

  &__text {
    color: $colorGray;
  }
}

@media (min-width: $screenGt) {
  .footer {
    position: relative;
    overflow: hidden;
    max-height: 614px;
    padding: 12px 12px 12px 36px;
    background-color: $colorWhite;

    &_ellipse {
      display: block;
      width: 746px;
      height: 745px;
      border-radius: 746px;
      background-color: $colorWhite;
      opacity: 0.5;
      filter: blur(240px);
      position: absolute;
      top: 0;
      right: 50px;
      z-index: 2;
    }

    &_leaves {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      width: 1030.657px;
      height: 707.255px;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &__logotype {
      img {
        width: 38px;
        height: 38px;
      }
    }

    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    &__contactsBlock {
      padding: 0;
      position: relative;
    }
    &__contactsWrapper {
      display: flex;
      justify-content: flex-start;
      gap: 50px;
      position: relative;
      z-index: 7;

      @include mediaEx {
        gap: 140px;
      }
    }
    &__contactsRight {
      display: flex;
      gap: 50px;
      justify-content: space-between;

      @include mediaEx {
        gap: 100px;
      }
    }
    &__contactsLeft {
      align-items: flex-start;
    }
    &__contact {
      align-items: flex-start;
      margin-right: 28px;
    }
    &__socials {
      justify-content: flex-start;

      a {
        justify-content: flex-start;
      }
    }
    &__privacyPolicy {
      align-items: flex-start;
    }
    &__linkPages {
      display: flex;
      flex-direction: column;
      gap: 10px;
      z-index: 7;
      p {
        white-space: nowrap;
        letter-spacing: 0;
      }
      &:last-of-type {
        a {
          white-space: nowrap;
        }
      }

      a {
        text-decoration: none;
        line-height: 30px;
        cursor: pointer;
        @include hover-gradient-text($colorGray);
      }
    }
    &__logotype {
      justify-content: flex-start;
    }

    &__formWrapper {
      position: relative;
      z-index: 3;
      display: block;
      flex-shrink: 1;
      > div {
        height: 100%;
      }
    }

    &__copyRights {
      text-align: left;
    }
  }
}

@include mediaLg {
  .footer {
    padding: 50px 12px 12px 36px;
  }
}
