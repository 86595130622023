@import "variables";
@import "_media";

@media (min-width: $media0) {
  // VideoBlock

  .videoInner {
    height: 700px;
  }

  .slideVideo {
    width: 100%;
    height: 700px;
    object-fit: cover;
  }

  // VideoSpinner
  .spinnerVideo {
    position: absolute;
    bottom: 28px;
    left: 16px;
  }

  .spinnerItem {
    position: relative;
    width: 24px;
    height: 24px;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      border-width: 2px;
      border-style: solid;
      border-radius: 50%;
    }
  }

  .spinnerItem.spinner-5 {
    @keyframes border-animate {
      0% {
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
      }

      25% {
        border-top-color: 2px solid $colorWhite;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
      }

      50% {
        border-top-color: 2px solid $colorWhite;
        border-right-color: 2px solid $colorWhite;
        border-bottom-color: transparent;
        border-left-color: transparent;
      }

      75% {
        border-top-color: 2px solid $colorWhite;
        border-right-color: 2px solid $colorWhite;
        border-bottom-color: 2px solid $colorWhite;
        border-left-color: transparent;
      }

      100% {
        border-top-color: 2px solid $colorWhite;
        border-right-color: 2px solid $colorWhite;
        border-bottom-color: 2px solid $colorWhite;
        border-left-color: 2px solid $colorWhite;
      }
    }

    &:before {
      width: 0;
      height: 0;
      border-color: $colorWhite;
      top: 0;
      left: 0;
      border-width: 2px;
      padding: 10px;
      border-style: solid;
      animation: border-animate 5s linear infinite;
    }

    &:after {
      width: 12px;
      height: 12px;
      top: 6px;
      left: 6px;
      background-color: $colorWhite;
      border: 2px solid transparent;
      border-radius: 50%;
      z-index: 44;
    }
  }

  // GoodsCard
  .goodsCard {
    position: relative;
    margin-bottom: 8px;

    .cardPhoto {
      width: 100%;
      aspect-ratio: 437 / 450;
    }

    .mobileLink {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
    }

    .cardLabel {
      position: absolute;
      top: 0;
      left: 0;
      padding: 12px 20px;
      background: rgba(0, 0, 0, 0.3);
      z-index: 2;
    }

    .cardLogo {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      margin: auto;
    }

    .bottomBg {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 3;
      background: linear-gradient(0deg, #252525, transparent);
      height: 150px;
      width: 100%;
    }

    .description-wrapper {
      position: absolute;
      left: 16px;
      right: 16px;
      bottom: 24px;
      z-index: 2;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: -webkit-fill-available;
      max-width: 90%;

      p {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 0;

        img {
          width: 18px;
          height: 10px;
        }
      }
    }
  }

  // FeedBackForm
  .feedBackForm {
    width: 100%;
    height: inherit;
    position: relative;
    z-index: 7;

    .feedBackInputWrapper {
      margin-bottom: 0;

      .feedBackInput {
        background: transparent;
        border: none;
        border-bottom: 1px solid $colorDarkWhite;
        width: 100%;
        resize: none;
        padding: 20px 12px;
        border-radius: 0;
        overflow: hidden;

        &::placeholder {
          color: $colorGray;
          font-size: 14px;
          font-family: $fontManrope;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }

  .ourMessengers {
    padding: 28px 28px 20px 28px;
    background: linear-gradient(
      225deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.04) 100%
    );
    backdrop-filter: blur(15px);
    box-shadow: 0 51px 64px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;

    div {
      background: linear-gradient(
        225deg,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0.04) 100%
      );
      width: 100%;
      padding: 10px 20px;
      margin-bottom: 8px;
      box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
      transition: 0.2s all;

      a {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        img {
          width: 24px;
          height: 24px;
        }

        p {
          margin: 0;
        }
      }
    }
  }
}

@media (min-width: $screenSm) {
  // GoodsCard
  .goodsCard {
    margin-bottom: 0;
  }
}
@media (min-width: $media1023) {
  // GoodsCard
  .goodsCard {
    .bgGradientBlack {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(0deg, #252525, transparent);
      height: 150px;
    }
  }

  // FeedBackForm
  .feedBackForm {
    .feedBackInputWrapper {
      .feedBackInput {
        width: 368px;
      }
    }

    p {
      max-width: 440px;
    }
  }

  .ourMessengers {
    width: 324px;
    margin-top: 0;
    margin-right: -30px;
    height: fit-content;
    margin-bottom: 0;

    div {
      &:hover {
        background: $colorAccent;

        p {
          color: $colorWhite;
        }
      }
    }
  }
}

@media (min-width: $media1200) {

  // RequestCall
  .requestCall-wrapper {
    padding: 12px 12px 12px 0px;
    background: $colorWhite;
    position: relative;
    z-index: 7;
  }

  .requestCall {
    height: 100%;
    padding: 60px;
    position: relative;
    z-index: 3;

    .requestCallForm {
      width: 100%;
      height: inherit;

      .requestCallInputWrapper {
        margin-bottom: 0;

        .requestCallInput {
          background: transparent;
          border: none;
          border-bottom: 1px solid $colorDarkWhite;
          width: 100%;
          resize: none;
          padding: 20px 12px;
          border-radius: 0;
          color: $colorWhite;
          overflow: hidden;

          &::placeholder {
            color: $colorWhite;
            opacity: 0.6;
            font-size: 14px;
            font-family: $fontManrope;
            font-weight: 500;
            line-height: 20px;
          }
        }
      }
    }
  }
}

@media (min-width: $media1440) {
  // CustomSelect
  .customSelect {
    & > div {
      & > div > div {
        color: var(--headerColor);
      }
    }

    .css-1nmdiq5-menu {
      .css-1n6sfyn-MenuList {
        div {
          color: var(--headerColor);
        }
      }
    }
  }

  // GoodsCard
  .goodsCard {
    overflow: hidden;

    &-image {
      transition: all 5s;

      &:hover {
        scale: 1.15;
      }
    }
  }

  // FeedBackForm
  .feedBackForm {
    p {
      max-width: 510px;
    }
  }
  .videoInner {
    height: 48.611vw;
  }

  .slideVideo {
    width: 100%;
    height: 48.611vw;
    object-fit: cover;
  }
}
