@import "styles/app.scss";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  // overflow: hidden;
  outline: none;
  border-radius: 30px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.25s ease;
  color: $colorAccent;
  font-family: $fontManrope;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.14px;
  box-sizing: border-box;
  position: relative; 

  @include mediaXx {
    border-width: 2px;
  }
}

.buttonMedium {
  height: 40px;
  padding-inline: 24px;
}

.buttonMedium.buttonWithIcon {
  height: 40px;
  padding-inline: 20px;
}

.buttonLarge {
  height: 48px;
  padding-inline: 24px;
}

.buttonLarge.buttonWithIcon {
  height: 48px;
  padding-inline: 20px;
}

.buttonOutlined {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;

  &:hover {
    text-decoration-line: underline;
  }
}

.buttonOutlined.buttonOutlinedDark {
  color: $colorAccent;
}

.buttonOutlined.buttonOutlinedWhite {
  color: $colorWhite;
}

.buttonOutlined.buttonWithIcon {
  padding: 0;
}


.whiteToDarkButton {
  color: $colorAccent;
  border: 1.6px solid $colorAccent;
  background: $colorWhite;
  backdrop-filter: blur(2px);

  &:hover {
    color: $colorWhite;
    background: $colorAccent;
  }

  @include mediaXx {
    border-width: 2px;
  }
}

.lightToWhiteButton {
  color: $colorWhite;
  border: 1.6px solid $colorWhite;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);

  &:hover {
    color: $colorAccent;
    background: $colorWhite;
  }

  @include mediaXx {
    border-width: 2px;
  }
}

.whiteToLightButton {
  color: $colorAccent;
  background: $colorWhite;
  border: 1.6px solid transparent;

  &:hover {
    color: $colorWhite;
    border-color: $colorWhite;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(2px);
  }

  @include mediaXx {
    border-width: 2px;
  }
}

.darkToWhiteButton {
  color: $colorWhite;
  background: $colorAccent;
  border: 1.6px solid $colorAccent;
  box-sizing: border-box;

  &:hover {
    color: $colorAccent;
    background: $colorWhite;
  }

  @include mediaXx {
    border-width: 2px;
  }
}

.darkToWhiteButton.disabledButton,
.whiteToDarkButton.disabledButton
 {
  color: $colorDarkWhite;
  border: 1.6px solid $colorDarkWhite;
  background-color: $colorWhite;
  backdrop-filter: blur(2px);

}

.whiteToLightButton.disabledButton,
.lightToWhiteButton.disabledButton
 {
  color: rgba(239, 239, 241, 0.5);
  border: 1.6px solid rgba(212, 212, 212, 0.5);
  background: rgba(239, 239, 241, 0.10);
  backdrop-filter: blur(2px);

}

.buttonOutlinedWhite.disabledButton,
.buttonOutlinedDark.disabledButton
 {
  color: $colorDarkWhite;
  text-decoration-line: none;
}

.grayBorder {
  color: $colorAccent;
  border: 1.6px solid $colorDarkWhite;
  background-color: $colorWhite;
  backdrop-filter: blur(5px);

  &:hover {
    border-color: $colorAccent;
  }

  @include mediaXx {
    border-width: 2px;
  }
}

.buttonSecondary {
  color: $colorAccent;
  border: 1.6px solid $colorLightGray;
  background-color: $colorLightGray;
  backdrop-filter: blur(5px);

  &:hover {
    background-color: $colorWhite;
    border-color: $colorDarkWhite;
  }
}

.buttonIcon {
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_left {
    margin-right: 4px;
  }
  &_right {
    margin-left: 4px;
  }
}

.indicator {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: $colorWhite;
  right: 12px;
  top: 4px;
  translate: 50% -50%;
  border: 2px solid #7EA2BC;
}