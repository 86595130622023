@import "styles/app.scss";

.logo {
  display: block;
  text-decoration: none;
  margin-top: -2px;

  @media (max-width: $screenSm) {
    &.sm {
      width: 41px;
      overflow: hidden;

      svg {
        height: 41px !important;
        width: auto !important;
      }
    }
  }
}

.sm svg {
  width: 163px;
  height: 20px;
}

.lg svg {
  width: 212px;
  height: 29px;
}
