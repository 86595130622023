@import "styles/app.scss";

@import "styles/app.scss";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  outline: none;
  border-radius: 30px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.25s ease;
  color: $colorAccent;
  font-family: $fontManrope;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.14px;

}

.buttonMedium {
  padding-inline: 24px;
  height: 40px;
}

.buttonMedium.buttonWithIcon,
.buttonLarge.buttonWithIcon {
  padding-inline: 20px;
}

.buttonLarge {
  padding-inline: 24px;
  height: 48px;
}

.buttonCircle.buttonMedium {
  width: 44px;
  height: 40px;
}


.buttonCircle.buttonLarge {
  width: 47px;
  height: 48px;
}

.outlined {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  color: $colorGray;

}

.outlined.buttonWithIcon {
    padding: 0;
  }

.outlined.disabled
 {
  color: $colorDarkWhite;
}
  
.active.outlined {
    color: $colorAccent;
}


.light {
    color: rgba(255, 255, 255, 0.60);
    border: 1.6px solid rgba(212, 212, 212, 0.50);
    background: rgba(239, 239, 241, 0.20);

    @include mediaXx {
        border-width: 2px;
    }
 
}

.light.disabled {
    color: $colorLightGray;
    border: 1.6px solid rgba(212, 212, 212, 0.10);
    background: rgba(239, 239, 241, 0.10);
}

.active.light {
    color: $colorWhite;
    border: 1.6px solid $colorWhite;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(2px);
}

.dark {
    color: $colorGray;
    border: 1.6px solid $colorGray;
    background: $colorWhite;
    backdrop-filter: blur(2px);
    box-sizing: border-box;
    @include mediaXx {
        border-width: 2px;
      }
}

.dark.disabled {
    color: $colorDarkWhite;
    border: 1.6px solid $colorDarkWhite;
    background: $colorWhite;
}

.active.dark {
  color: $colorWhite;
  background: $colorAccent;
  border: 1.6px solid $colorAccent;
  box-sizing: border-box;
}

.secondary {
  background-color: $colorWhite;
  color: $colorAccent;
  border: 1.6px solid $colorDarkWhite;
  backdrop-filter: blur(5px);
}

.active.secondary {
  background-color: $colorLightGray;
  border-color: $colorLightGray;
  
}


.buttonIcon {
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_left {
    margin-right: 4px;
  }
  &_right {
    margin-left: 4px;
  }
}
