@import "styles/app.scss";

.icon {
  margin-left: 5px;
  rotate: 180deg;
  margin-top: 3px;
  &__rotate {
    margin-top: 0;
    rotate: 0deg;
  }
}

.visible {
  opacity: 1;
}

.invisible {
  opacity: 0;
  display: none !important;
}
