@import "styles/app";

.messenger {
  @include bgMatteGlassLight;
  position: relative;

  &__wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 26px 20px;
    backdrop-filter: blur(15px);
    box-shadow: 0 51px 64px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;

    @include mediaMd {
      width: 324px;
    }

    @include mediaXx {
      width: 360px;
      padding: 36px 27px;
    }
  }

  &__bg {
    border-radius: 438px;
    mix-blend-mode: soft-light;
    filter: blur(100px);
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: 438px;
    height: 438px;

    @include mediaXx {
      border-radius: 529px;
      width: 529px;
      height: 529px;
    }
  }

  &__title {
    margin-bottom: 13px;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    gap: 11px;
  }

  &__card {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    @include bgLowWhite;

    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.1);
    transition: background 0.2s;
    path {
      fill: $colorAccent;
    }
    rect {
      fill: $colorAccent;
    }
    &:hover {
      color: $colorWhite;
      background-color: $colorAccent;
      .messenger__icon {
        &,
        path,
        rect {
          color: $colorWhite;
          fill: $colorWhite;
        }
      }
      .messenger__icon--fb {
        path {
          fill: $colorAccent;
        }
      }
    }
  }

  &__iconWrapper {
    width: 37px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    width: 21px;
    height: 21px;
    &--wa {
      width: 19px;
      height: 19px;
    }
    &--fb {
      path {
        fill: $colorWhite;
      }
    }
  }
}
