@import "styles/app.scss";

.contactForm {
  padding: 20px 16px 40px;
  background: $colorWhite;
  width: 100%;
  max-width: 550px;

  @include mediaLg {
    padding: 40px;
  }
 
  &--modal {
    padding: 0;
  }
  &_title {
    @include mediaLg {
      margin-bottom: 10px;
    }
  }

  &_form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &--apartments {
    padding: 30px 20px;
    @include mediaLg {
      padding: 30px;
    }
  }
}

.textarea:is(div) {
  margin-bottom: 20px;
}

.agreement {
  margin-top: 10px;
}