@import "styles/app.scss";

.input {
  padding: 20px 10px;
  width: 100%;
  border: unset;
  background: transparent;
  outline: none;
  caret-color: #d4d4d4;
  color: #22364a;
  @include p3;
  transition: color 0.2s, border-color 0.2s;
  resize: none;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
    font-size: inherit;
    background-color: transparent !important;
  }

  &::placeholder {
    color: #ffffff99;
  }
  &--blue {
    caret-color: #ffffff99;
    color: $colorWhite;
  }
  &--white {
    color: $colorAccent;
    &::placeholder{
      color: $colorGray;
    }
  }

  &__wrapper {
    position: relative;
    margin-bottom: 23px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d4d4d4;

    &--error {
      border-color: #ae1616;
    }

    &--valid {
      border-color: #4c8832;
    }
    &--blue {
      border-bottom: 1px solid #ffffff99;
      &.input__wrapper--error {
        color: $colorErrorLight;
        border-color: $colorErrorLight;
      }
      > .input__error {
        color: $colorErrorLight;
      }
    }
    &--white {
      border-bottom: 1px solid #DCDCDC;
      &.input__wrapper--error {
        color: $colorError;
        border-color: $colorError;
      }
      > .input__error {
        color: $colorError;
      }
    }
  }
  &__error {
    position: absolute;
    color: #ae1616;
    bottom: -15px;
    translate: 0 50%;
    @include p4;
  }
}
