@import "components";
@import "custom";
@import "headers";
@import "slider";
@import "text";
@import "mainPage";

@import "app";

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  outline: none !important;
  box-sizing: border-box;
}

html {
  min-height: 100%;
  scrollbar-gutter: stable;
  scroll-behavior: smooth !important;
  scroll-padding-top: 60px;
  @include mediaLg{
    scroll-padding-top: 80px;
  }
}

body {
  overflow-x: hidden;
  margin: 0 !important;
  padding: 0 !important;
  text-rendering: geometricPrecision;
  

  &.scroll-disabled {
    overflow: hidden;
  }
}

header {
  @include printer{
    display: none !important;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

body,
html,
#root,
#__next {
  display: flex;
  width: 100%;
  font-family: $fontManrope;
  font-weight: 300;
  color: $colorAccent;
  font-size: 14px;
  border: 0;
  line-height: 1;
  position: relative;
}

#root {
  flex-direction: column;
}

input {
  &:focus {
    box-shadow: none !important;
  }
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.react-tabs > ul {
  padding: 0;
  margin-left: -8px;
  margin-right: -8px;
}

.image-bg-wrapper {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.image-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: -1;
}
