@import "styles/app.scss";

.button {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $fontManrope;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.14px;
  font-weight: 700;
  box-sizing: border-box;
  border-width: 1.6px;
  border-style: solid;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 9px;
  transition: all 0.25s ease;

  @include mediaXx {
    border-width: 2px;
  }

}

.buttonSecondary {
  border: none;
  background-color: transparent;
}

.buttonSecondary.buttonMixed,
.buttonSecondary.buttonDark
 {
  color: $colorAccent;
}

.buttonSecondary.buttonLight {
  color: $colorWhite;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
}

.buttonAccent.buttonDark {
  border-color: $colorWhite;
  color: $colorWhite;
  background: rgba(0, 0, 0, 0.20);
  backdrop-filter: blur(2.0168230533599854px);

  &:hover {
    background: rgba(0, 0, 0, 0.30);
  }
}

.buttonAccent.buttonLight {
  border-color: $colorWhite;
  color: $colorWhite;
  background: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(2.0168230533599854px);

  &:hover {
    background: rgba(255, 255, 255, 0.50);
  }
}

.buttonAccent.buttonMixed {
  border-color: $colorAccent;
  color: $colorAccent;
  background: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(2.0168230533599854px);

  &:hover {
    background-color: $colorAccent;
    color: $colorWhite;
  }
}

.buttonAccent.buttonMixed.buttonHoverGradient {
  &:hover {
    color: $colorWhite;
    border-color: transparent;
    @include bgGradient135Transition(".button");

  }
}

.buttonSecondary.disabledButton {
  color: $colorDarkWhite;
}